import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import Loading from "../components/loading";

import { CurrentUserContext } from "../providers/auth";
import Services, { isUnauthorizedError } from "../services";
import { lamppostDisplayName } from "../utils/lamppost";
import Links from "../utils/links";
import {
    formatDate,
    formatDateStr,
    momentTz,
    timeFormats,
    addDate,
    getDiffDays
} from "../utils/time";

const ReportPage = () => {
    const [checkCount, setCheckCount] = useState(0);

    const forceCheckSession = () => {
        setCheckCount(checkCount + 1);
    };

    return (
        <Layout
            link={Links.Report}
            seoTitle="Report"
            forceCheckSessionCount={checkCount}
        >
            <TimeSeriesReport forceCheckSession={forceCheckSession} />
        </Layout>
    );
};

export default ReportPage;

const formatMonthStr = (month) =>
    formatDateStr(month, timeFormats.YYYYMM, timeFormats.month);

const TimeSeriesReport = ({ forceCheckSession }) => {
    const currentUser = useContext(CurrentUserContext);
    const lamppostService = Services(currentUser).lamppost;

    const [lampposts, setLampposts] = useState([]);
    const [tsReports, setTsReports] = useState([]);
    const [lamppostId, setLamppostId] = useState("");
    const [month, setMonth] = useState("");
    const [metric, setMetric] = useState("");
    const [tsReport, setTsReport] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadErr, setLoadErr] = useState("");

    const handleAuthError = (err) => {
        if (isUnauthorizedError(err)) {
            forceCheckSession();
        }
    };

    const updateTsReportSelection = (lamppostId, reports, month, metric) => {
        let selected = true;
        if (month) {
            if (reports.filter((r) => r.month === month).length === 0) {
                setMonth("");
                setMetric("");
                selected = false;
            } else if (metric) {
                if (
                    reports.filter(
                        (r) => r.month === month && r.metric === metric
                    ).length === 0
                ) {
                    setMetric("");
                    selected = false;
                }
            } else {
                selected = false;
            }
        } else {
            setMetric("");
            selected = false;
        }
        if (selected) {
            getTimeSeriesReport(lamppostId, month, metric);
        } else {
            setTsReport(null);
        }
    };

    const getLamppostTimeSeriesReports = (lamppostId) => {
        setLoading(true);
        lamppostService
            .getTimeSeriesReports(lamppostId)
            .then((d) => {
                setTsReports(d.reports);
                updateTsReportSelection(lamppostId, d.reports, month, metric);
            })
            .catch((err) => {
                handleAuthError(err);
                setTsReports([]);
                setMonth("");
                setMetric("");
                setTsReport(null);
                setLoadErr(
                    "Unable to load time series reports of this lamppost."
                );
            })
            .finally(() => setLoading(false));
    };

    const getTimeSeriesReport = (lamppostId, month, metric) => {
        if (lamppostId && month && metric) {
            setLoading(true);
            lamppostService
                .getTimeSeriesReport(lamppostId, month, metric)
                .then((d) => {
                    setTsReport(d);
                })
                .catch((err) => {
                    handleAuthError(err);
                    setTsReport(null);
                    setLoadErr("Unable to load time series report.");
                })
                .finally(() => setLoading(false));
        } else {
            setTsReport(null);
        }
    };

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        setLoading(true);
        lamppostService
            .getLampposts()
            .then((d) => {
                const ls = d.lampposts || [];
                setLampposts(ls);
                let lamppostId = ls.length > 0 ? ls[0].lamppost_id : "";
                setLamppostId(lamppostId);
                lamppostId && getLamppostTimeSeriesReports(lamppostId);
            })
            .catch((err) => {
                handleAuthError(err);
                setLampposts([]);
                setTsReports([]);
                setMonth("");
                setMetric("");
                setLoadErr("Unable to load lampposts.");
            })
            .finally(() => setLoading(false));
    }, [currentUser]);

    const onChangeLamppost = (e) => {
        const newLamppostId = e.target.value;
        setLamppostId(newLamppostId);
        getLamppostTimeSeriesReports(newLamppostId);
    };

    const onChangeMonth = (e) => {
        const newMonth = e.target.value;
        setMonth(newMonth);
        updateTsReportSelection(lamppostId, tsReports, newMonth, metric);
    };

    const onChangeMetric = (e) => {
        const newMetric = e.target.value;
        setMetric(newMetric);
        updateTsReportSelection(lamppostId, tsReports, month, newMetric);
    };

    const formatMetricStr = (metric) => {
        if (metric === "NO" || metric === "NO2") {
            return `${metric} (ppb)`;
        } else if (metric === "PM25") {
            return "PM2.5 (μg/m3)";
        }
        return metric;
    };

    return (
        <>
            <div className="title-h1">Time Series Report</div>
            <div className="flexrow items-center">
                <span className="label">Lamppost:</span>
                <select
                    className="input-inline px-4"
                    style={{ width: "400px" }}
                    id="lamppost"
                    type="text"
                    value={lamppostId}
                    onChange={onChangeLamppost}
                    disabled={loading}
                >
                    {lampposts.map((l, idx) => {
                        return (
                            <option key={`l_${idx}`} value={l.lamppost_id}>
                                {lamppostDisplayName(l)}
                            </option>
                        );
                    })}
                </select>
                <span className="label ml-8">Month:</span>
                <select
                    className="input-inline px-4"
                    style={{ width: "180px" }}
                    id="month"
                    type="text"
                    value={month}
                    onChange={onChangeMonth}
                    disabled={loading}
                >
                    <option hidden disabled value="">
                        {" "}
                        -- Select --{" "}
                    </option>
                    {tsReports
                        .map((r) => r.month)
                        .filter((m, idx, a) => a.indexOf(m) === idx)
                        .sort((a, b) => (a === b ? 0 : a > b ? -1 : 1))
                        .map((m, idx) => {
                            return (
                                <option key={`m_${idx}`} value={m}>
                                    {formatMonthStr(m)}
                                </option>
                            );
                        })}
                </select>
                <span className="label ml-8">Pollutant:</span>
                <select
                    className="input-inline px-4"
                    style={{ width: "180px" }}
                    id="metric"
                    type="text"
                    value={metric}
                    onChange={onChangeMetric}
                    disabled={loading}
                >
                    <option hidden disabled value="">
                        {" "}
                        -- Select --{" "}
                    </option>
                    {tsReports
                        .filter((r) => r.month === month)
                        .map((r) => r.metric)
                        .sort()
                        .map((m, idx) => {
                            return (
                                <option key={`p_${idx}`} value={m}>
                                    {formatMetricStr(m)}
                                </option>
                            );
                        })}
                </select>
            </div>
            {loading ? (
                <Loading />
            ) : !loadErr && tsReport ? (
                <div className="mt-3 flex flex-row">
                    <TimeSeriesReportDetail report={tsReport} />
                </div>
            ) : (
                <div className="text-center py-32">
                    {loadErr ? (
                        <span className="red">{loadErr}</span>
                    ) : tsReports.length === 0 ? (
                        "No time series report for this lamppost."
                    ) : (
                        "Please select lamppost, month and pollutant."
                    )}
                </div>
            )}
        </>
    );
};

TimeSeriesReport.propTypes = {
    forceCheckSession: PropTypes.func.isRequired
};

const TimeSeriesReportDetail = ({ report }) => {
    const { from, hourly_avg, daily_avg, statistic, last_updated_at } = report;
    const startTime = momentTz(
        formatDate(from, timeFormats.YYYYMMDD),
        timeFormats.YYYYMMDD
    ).toDate();
    const rows = daily_avg.map((d) => {
        const { observation_time, value } = d;
        const dayEnd = new Date(observation_time);
        const dayStart = addDate(dayEnd, -1, "d");
        const hourlyAvgs = hourly_avg
            .filter((h) => {
                const time = new Date(h.observation_time).getTime();
                return time > dayStart.getTime() && time <= dayEnd.getTime();
            })
            .map((h) => h.value);
        let min = null;
        let max = null;
        let validAvgs = hourlyAvgs.filter((v) => v !== null);
        if (validAvgs.length > 0) {
            min = Math.min(...validAvgs);
            max = Math.max(...validAvgs);
        }
        return {
            day: getDiffDays(dayEnd, startTime),
            hourlyAvgs,
            min,
            max,
            avg: value
        };
    });
    let min1h = null;
    let max1h = null;
    let min24h = null;
    let max24h = null;
    let validMins = rows.map((d) => d.min).filter((v) => v !== null);
    if (validMins.length > 0) {
        min1h = Math.min(...validMins);
    }
    let validMaxs = rows.map((d) => d.max).filter((v) => v !== null);
    if (validMaxs.length > 0) {
        max1h = Math.max(...validMaxs);
    }
    let validAvgs = rows.map((d) => d.avg).filter((v) => v !== null);
    if (validAvgs.length > 0) {
        min24h = Math.min(...validAvgs);
        max24h = Math.max(...validAvgs);
    }
    const {
        avg,
        median,
        sd,
        pc50,
        pc90,
        pc95,
        pc98,
        pc99,
        capture_rate
    } = statistic;

    const formatData = (data) => (data === null ? "*****" : data.toFixed(1));

    return (
        report && (
            <>
                <div style={{ width: "100%" }}>
                    <span className="label">
                        (Generated at:{" "}
                        {formatDate(last_updated_at, timeFormats.dayTime)})
                    </span>
                    <table
                        style={{ fontSize: "0.8rem" }}
                        className="w-full ts-table"
                    >
                        <thead>
                            <tr>
                                <th style={{ width: "7%" }} rowSpan="2">
                                    Day
                                </th>
                                <th
                                    style={{
                                        width: "72%"
                                    }}
                                    colSpan="24"
                                >
                                    Hour
                                </th>
                                <th style={{ width: "7%" }} rowSpan="2">
                                    Min
                                </th>
                                <th style={{ width: "7%" }} rowSpan="2">
                                    Max
                                </th>
                                <th style={{ width: "7%" }} rowSpan="2">
                                    Mean
                                </th>
                            </tr>
                            <tr>
                                {[...Array(24).keys()].map((h, idx) => (
                                    <th
                                        style={{
                                            width: "3%",
                                            textAlign: "right"
                                        }}
                                        key={`h_${idx}`}
                                    >
                                        {h + 1}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((r, idx) => (
                                <tr key={`row_${idx}`}>
                                    <td>{r.day}</td>
                                    {r.hourlyAvgs.map((a, aidx) => (
                                        <td key={`cell_${idx}_${aidx}`}>
                                            {formatData(a)}
                                        </td>
                                    ))}
                                    <td>{formatData(r.min)}</td>
                                    <td>{formatData(r.max)}</td>
                                    <td>{formatData(r.avg)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <span className="label">Monthly Statistics:</span>
                    <table
                        style={{ fontSize: "0.8rem" }}
                        className="w-full ts-table stat-table"
                    >
                        <thead>
                            <tr>
                                <th style={{ width: "6%" }} rowSpan="2">
                                    Mean
                                </th>
                                <th style={{ width: "6%" }} rowSpan="2">
                                    Median
                                </th>
                                <th style={{ width: "16%" }} rowSpan="2">
                                    Standard Deviation
                                </th>
                                <th
                                    style={{
                                        width: "30%"
                                    }}
                                    colSpan="5"
                                >
                                    Percentiles
                                </th>
                                <th
                                    style={{
                                        width: "24%"
                                    }}
                                    colSpan="4"
                                >
                                    Range
                                </th>
                                <th style={{ width: "18%" }} rowSpan="2">
                                    Data Capture Rate (%)
                                </th>
                            </tr>
                            <tr>
                                <th style={{ width: "6%" }}>50</th>
                                <th style={{ width: "6%" }}>90</th>
                                <th style={{ width: "6%" }}>95</th>
                                <th style={{ width: "6%" }}>98</th>
                                <th style={{ width: "6%" }}>99</th>
                                <th style={{ width: "6%" }}>Min (1h)</th>
                                <th style={{ width: "6%" }}>Max (1h)</th>
                                <th style={{ width: "6%" }}>Min (24h)</th>
                                <th style={{ width: "6%" }}>Max (24h)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{formatData(avg)}</td>
                                <td>{formatData(median)}</td>
                                <td>{formatData(sd)}</td>
                                <td>{formatData(pc50)}</td>
                                <td>{formatData(pc90)}</td>
                                <td>{formatData(pc95)}</td>
                                <td>{formatData(pc98)}</td>
                                <td>{formatData(pc99)}</td>
                                <td>{formatData(min1h)}</td>
                                <td>{formatData(max1h)}</td>
                                <td>{formatData(min24h)}</td>
                                <td>{formatData(max24h)}</td>
                                <td>{formatData(capture_rate)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ fontSize: "0.8rem" }}>
                        Notes: Invalid data or statistics are shown as
                        &quot;*****&quot;.
                    </div>
                </div>
            </>
        )
    );
};

TimeSeriesReportDetail.propTypes = {
    report: PropTypes.shape({
        from: PropTypes.string.isRequired,
        hourly_avg: PropTypes.arrayOf(
            PropTypes.shape({
                observation_time: PropTypes.string.isRequired,
                value: PropTypes.number
            })
        ).isRequired,
        daily_avg: PropTypes.arrayOf(
            PropTypes.shape({
                observation_time: PropTypes.string.isRequired,
                value: PropTypes.number
            })
        ).isRequired,
        statistic: PropTypes.shape({
            avg: PropTypes.number,
            median: PropTypes.number,
            sd: PropTypes.number,
            pc50: PropTypes.number,
            pc90: PropTypes.number,
            pc95: PropTypes.number,
            pc98: PropTypes.number,
            pc99: PropTypes.number,
            capture_rate: PropTypes.number
        }).isRequired,
        last_updated_at: PropTypes.string.isRequired
    }).isRequired
};
